import React from "react";
import theme from "theme";
import { Theme, Image, Text, Box, Icon, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdPhone, MdMailOutline } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Vinyl Haven
			</title>
			<meta name={"description"} content={"Her Plak Bir Hikaye Anlatır - Vinyl Haven'ın Ses Manzaralarına Dalış\n"} />
			<meta property={"og:title"} content={"Vinyl Haven"} />
			<meta property={"og:description"} content={"Her Plak Bir Hikaye Anlatır - Vinyl Haven'ın Ses Manzaralarına Dalış\n"} />
			<meta property={"og:image"} content={"https://ottomanodyssey.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 0 0" quarkly-title="Contacts-6">
			<Override slot="SectionContent" flex-direction="column" min-width="100%" width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				margin="0px 0px 50px 0px"
				lg-align-items="center"
			>
				<Image
					src="https://ottomanodyssey.live/img/map.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					sm-height="280px"
					margin="0px 30px 0px 30px"
					lg-margin="0px 30px 30px 30px"
					lg-height="auto"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
					align-items="flex-start"
					padding="0px 50px 0px 50px"
					sm-padding="0px 15px 0px 15px"
					sm-margin="0px 0px 0 0px"
				>
					<Text
						margin="0px 10px 25px 0px"
						color="#22156d"
						font="normal 500 28px/1.2 --fontFamily-sansHelvetica"
						padding="0px 10px 0px 0px"
						border-color="#c9d0d7"
						sm-border-width={0}
					>
						Temasta olmak
					</Text>
					<Text margin="0px 0px 20px 0px" font="normal 600 38px/1.2 --fontFamily-sansHelvetica" color="#AA9CDB" sm-font="normal 600 28px/1.2 --fontFamily-sansHelvetica">
						Bizi arayın: 553 678 08 43
					</Text>
					<Text margin="0px 0px 32px 0px" font="--lead" color="#8b9197">
						Geçmişimize takılıp kalmamakla birlikte, sizi devam eden hikayemizin bir parçası olmaya davet ediyoruz. Eve götürdüğünüz her plak, mağazamızda yaptığınız her sohbet, Vinyl Haven'ı müzik camiasında eşsiz ve değerli bir yer yapan zengin dokuya katkıda bulunuyor.
					</Text>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0 0 0"
				lg-grid-template-columns="repeat(2, 1fr)"
				padding="50px 50px 50px 50px"
				background="#AA9CDB"
				align-items="center"
				lg-grid-gap="36px 34px"
				md-grid-template-columns="1fr"
				sm-padding="35px 15px 35px 15px"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
				>
					<Icon
						category="md"
						icon={MdLocationOn}
						size="54px"
						margin="0px 20px 0 0px"
						color="#AA9CDB"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
					>
						<Link
							color="--light"
							text-decoration-line="initial"
							font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
						>
							Caferağa, Hacı Şükrü Sk.5
						</Link>
						<Text
							margin="0px 0px 0 0px"
							font="normal 300 16px/1.2 --fontFamily-sans"
							text-align="center"
							color="--light"
							md-margin="0px 0px 0px 0px"
						>
							{" "}34710 Kadıköy/İstanbul, Türkiye
						</Text>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
				>
					<Icon
						category="md"
						icon={MdPhone}
						size="54px"
						margin="0px 20px 0 0px"
						color="#AA9CDB"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
					>
						<Link
							href="tel:+90 553 678 08 43"
							color="--light"
							text-decoration-line="initial"
							font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
						>
							+90 553 678 08 43
						</Link>
						<Text
							margin="0px 0px 0 0px"
							font="normal 300 16px/1.2 --fontFamily-sans"
							text-align="center"
							color="--light"
							md-margin="0px 0px 0px 0px"
						>
							Bizi arayın
						</Text>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
				>
					<Icon
						category="md"
						icon={MdMailOutline}
						size="54px"
						margin="0px 20px 0 0px"
						color="#AA9CDB"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
					>
						<Link
							href="mailto:contact@ottomanodyssey.live"
							color="--light"
							text-decoration-line="initial"
							font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
						>
							contact@ottomanodyssey.live
						</Link>
						<Text
							margin="0px 0px 0 0px"
							font="normal 300 16px/1.2 --fontFamily-sans"
							text-align="center"
							color="--light"
							md-margin="0px 0px 0px 0px"
						>
							Temasta olmak
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});